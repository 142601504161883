<template>
	<div class="container d-flex flex-column text-center mt-5">
		<h1>Ďakujeme za Vašu objednávku</h1>
		<p v-if="isCourse">
			Faktúra Vám príde e-mailom v najbližších dňoch. Bližšie informácie o chode kurzu Vám pošleme tesne pred nástupom na kurz.
		</p>
		<p v-else-if="isLevelUp">
			Faktúra Vám príde e-mailom v najbližších dňoch. Bližšie informácie Vám zašleme čoskoro.
		</p>
		<p v-else>
			Faktúra Vám príde e-mailom v najbližších dňoch. Bližšie informácie o tábore Vám pošleme týždeň pred nástupom.
		</p>
	</div>
</template>
<script>
import productUtils from '@/plugins/app/_utils/product.util.js'

export default {
	async created() {
		// this._trackDataLayerEvent()
		const order = await this.$store.dispatch('order/getOrder')
		this._trackOrderSendEvent(order)
		this.$store.commit('order/reseted', true)
	},

	computed: {
		type() {
			return this.$route.query.type
		},

		isCourse() {
			return productUtils.isCourse(this.type)
		},
		isLevelUp() {
			return productUtils.isLevelUp(this.type)
		}
	},

	methods: {
		_trackDataLayerEvent() {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'purchase',
				pageTitle: 'Ďakujeme za Vašu objednávku'
			})
		},

		_trackOrderSendEvent(order) {
			if (!order) return

			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({ ecommerce: null })

			const items = order.order_registrations.map(orderRegistration => {
				return {
					id: orderRegistration.product_period.product.id,
					type: 'Online kurz', // NEVIEME VYHODNOTIT - Typ produktu ['Online kurz', 'Prezenčný kurz', 'Tábor', ...]
					category: orderRegistration.product_period.product.product_type.name,
					name: orderRegistration.product_period.product.name,
					description: orderRegistration.product_period.product.description,
					price: orderRegistration.product_period.product.price,
					quantity: orderRegistration.order_registration_dates.length,
					minimum_quantity: orderRegistration.product_period.product.minimum_selected_days,
					location: {
						city: orderRegistration.product_period.place.town,
						name: orderRegistration.product_period.place.name,
						street: orderRegistration.product_period.place.street
					},
					start_date: orderRegistration.product_period.start_date,
					end_date: orderRegistration.product_period.end_date
				}
			})

			window.dataLayer.push({
				event: 'purchase',
				ecommerce: {
					id: order.id,
					currency: 'EUR',
					value: order.price,
					tax: '',
					shipping: '',
					coupon: order.coupon,
					discount: order.gift_voucher,
					items: items,
					payment: {
						installments: order.is_partial_payment ? order.partial_payments : 1,
						method: this.$store['order/isCardPayment'] ? 'Platobná karta' : 'Prevod na účet',
						type: order.is_partial_payment ? 'Mesačná splátka' : 'Jednorázová platba'
					}
				}
			})
		}
	}
}
</script>
